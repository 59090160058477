import React from "react"
import Layout from "hoc/Layout"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import Header from "containers/Header/Header"
import Hr from "components/Hr/Hr"
import Font42 from "components/Fonts/Font42"
import RecrutationSteps from "containers/RecrutationsSteps/RecrutationSteps"
import FormSection from "containers/Homepage/FormSection/FormSection"

const ForNannyRecrutation = () => {
  const breadcrumbsData = [
    {
      title: "Startseite",
      link: "/",
    },
    {
      title: "Für Kunden",
      link: "/fur-kunden/phasen-des-vertragsabschlusses",
    },
    "Phasen des Vertragsabschlusses",
  ]

  const { recruitmentMain } = useStaticQuery(
    graphql`
      query {
        recruitmentMain: file(name: { eq: "recruitment-main" }) {
          id
          childImageSharp {
            gatsbyImageData(
              backgroundColor: "transparent"
              quality: 95
              placeholder: NONE
            )
          }
        }
      }
    `
  )

  const mainImage = getImage(recruitmentMain)

  return (
    <Layout
      breadcrumbsData={breadcrumbsData}
      seoTitle="Phasen des Vertragsabschlusses"
    >
      <Header
        title={"Vertragsschluss"}
        titleDescription={"Wir führen Sie bequem durch diesen Prozess."}
        mainImage={mainImage}
        mainImageAlt={"Vertragsschluss"}
      />
      <Hr mb={4} mt={6} />
      <Font42 mb={8} textAlign="center">
        Phasen des Vertragsabschlusses
      </Font42>
      <RecrutationSteps />
      <FormSection noBottom />
    </Layout>
  )
}

export default ForNannyRecrutation
